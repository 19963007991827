<!--name: "addReduction.vue"-->
<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">操作提醒</template>
        <el-alert
            title="满减活动包括店铺所有商品，活动时间不能和已有活动重叠"
            type="warning"
            :closable="false">
        </el-alert>
        <el-alert
            title="每添加一个规则都需点击确定规则设置按钮，生成一条规则，提交之后才可成功添加"
            type="warning"
            :closable="false">
        </el-alert>
        <el-alert
            title="点击确定规则设置按钮生成的规则都有一个删除按钮，如不需要该条规则点击删除按钮即可删除"
            type="warning"
            :closable="false">
        </el-alert>
      </el-collapse-item>
    </el-collapse>

    <el-form style="margin-top: 30px" ref="form" label-width="130px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="活动名称" prop="name">
        <el-input v-model="form.name" size="small" placeholder="请输入活动名称" style="width: 25%"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动时间" prop="">
        <el-date-picker
            v-model="query_time"
            size="small"
            type="datetimerange"
            @change="getTimeSection"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动类型" prop="">
        <el-radio v-model="form.type" :label="0">满N元</el-radio>
        <el-radio v-model="form.type" :label="1">满N件</el-radio>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否跨店" prop="">
        <el-switch
            v-model="form.is_active"
            active-color="#13ce66"
            active-text="是"
            active-value="1"
            inactive-color="#ff4949"
            inactive-text="否"
            inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="优惠设置">
        <div style="width: 70%; line-height: 30px; background-color: #EEEEEE">
          <div style="width: 50%; padding-left: 5px; display: inline-block">活动层级1:</div>
          <div
              style="width: 45%; text-align: right; color: red; display: inline-block">删除
          </div>
        </div>
        <div>优惠门槛: 满
          <el-input v-model="form.money" style="width: 100px" size="small"></el-input>
          元
        </div>
        <div>
          优惠内容:
          <el-checkbox v-model="form.checked" true-label="1" false-label="0">订单金额优惠</el-checkbox>
          <span v-show="form.checked == 1" style="margin-left: 20px">满
          <el-input v-model="form.money" style="width: 100px" size="small"></el-input>
          元</span>
        </div>
        <div>
          <el-checkbox v-model="form.checked" true-label="1" false-label="0">包邮</el-checkbox>
          <div style="color: #8c939d; font-size: 10px">仅参与该活动的商品包邮，非整单包邮</div>
        </div>
        <div>
          <el-checkbox v-model="form.checked" true-label="1" false-label="0">送优惠券</el-checkbox>
          <div style="color: #8c939d; font-size: 10px">请确认优惠券数量是否充足，优惠券数量不足将导致赠送失败</div>
          <el-select
              v-model="couponObj"
              clearable
              multiple
              placeholder="请选择"
              style="width: 200px"
              size="small"
              @change="getCouponNum"
              @focus="monitoringCoupon"
          >
            <el-option
                v-for="item in couponArr"
                :key="item.id"
                :disabled="couponType"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
          <el-button size="small" type="primary" style="margin-left: 10px" @click="addCoupons">添加优惠券(最多三张)</el-button>
          <el-table v-loading="list_state" size="small" border :data="list" style="width: 500px">
            <el-table-column
                align="center"
                prop="name"
                label="优惠券名">
            </el-table-column>
            <el-table-column
                width="100"
                align="center"
                prop="quantity"
                label="现有余量">
            </el-table-column>
            <el-table-column align="center" label="操作" width="60">
              <template slot-scope="scope">
                <el-button type="text" @click="list.splice(scope.$index,1)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-checkbox v-model="form.checked" true-label="1" false-label="0">送赠品</el-checkbox>
          <div style="color: #8c939d; font-size: 10px">仅支持购买实物商品送赠品，送完即止</div>
          <el-button size="small" type="primary" style="margin-left: 10px" @click="addCoupons">选择赠品</el-button>
          <el-table v-loading="list_state" size="small" border :data="list" style="width: 500px">
            <el-table-column
                align="center"
                prop="name"
                label="赠品">
            </el-table-column>
            <el-table-column
                width="100"
                align="center"
                prop="quantity"
                label="赠品数">
            </el-table-column>
            <el-table-column align="center" label="操作" width="60">
              <template slot-scope="scope">
                <el-button type="text" @click="list.splice(scope.$index,1)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动商品" prop="goods_type">
        <el-radio-group v-model="form.goods_type" size="small">
          <el-radio-button :label="0">全部商品参与</el-radio-button>
          <el-radio-button :label="1">指定商品参与</el-radio-button>
          <el-radio-button :label="2">指定分类</el-radio-button>
          <el-radio-button :label="3">指定店铺 to be...</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="form.goods_type == 1" style="margin-bottom: 20px" label="指定商品" prop="">
        <el-table :data="s_list" size="small" style="width: 70%"
                  @selection-change="setSelectRows">
          <el-table-column align="center" show-overflow-tooltip type="selection"/>
          <el-table-column
              align="center"
              label="商品图片"
              prop="imgUrl"
          >
            <template #default="{row}">
              <el-image v-if="row.imgUrl" :src="row.imgUrl" class="commodity_pic"/>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="商品名称"
              prop="name"
          />
          <el-table-column
              align="center"
              label="商品价格"
              prop="original_price"
          />
          <el-table-column
              align="center"
              label="库存"
              prop="stock"
          />
          <el-table-column
              align="center"
              label="折扣价"
              prop="price"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.price" v-model="s_list[scope.$index].price"
                        placeholder="请输入内容" :value="scope.row.price"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].price" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button icon="el-icon-plus" type="primary" @click="showPop = true" size="small" style="margin-top: 10px">
          选择商品
        </el-button>
        <el-button v-if="s_list.length > 0" icon="el-icon-delete" size="small" type="danger" style="margin-top: 10px"
                   @click="handleDelete">批量删除
        </el-button>
        <el-button v-else icon="el-icon-delete" type="danger" size="small" style="margin-top: 10px" disabled>批量删除
        </el-button>
        <PopView :visible.sync="showPop" :chooseGoods="chooseGoods" title="选择商品" @confirm="showPopSubmit"></PopView>
      </el-form-item>
      <el-form-item v-show="form.goods_type == 2" label="指定分类">
        <el-select
            v-model="form.qualified_type"
            multiple
            size="small"
            placeholder="请选择分类"
            style="width: 400px"
        >
          <el-option
              v-for="item in valueArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="备注" prop="remark">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            v-model="form.remark"
            placeholder="请输入活动备注"
            style="width: 25%"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>

import PopView from "@/views/components/popView";
import {apiGetCategoryList, apiGetDiscount, apiGetNotPageCoupon} from "@/request/api";

export default {
  name: 'addReduction',
  components: {PopView},
  props: {
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list_state: false,
      couponObj: {},
      couponArr: [],
      couponType: false,
      activeName: '',
      showPop: false,
      query_time: [],
      selectRows: '',
      list: [],
      s_list: [],
      valueArr: [],
      form: {
        id: '',
        name: '',
        remark: '',
        begin_time: '',
        end_time: '',
        goods: [],
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '活动名称不能为空'},
        ],
        remark: [
          {required: true, trigger: 'blur', message: '活动备注不能为空'},
        ],
        /*begin_time: [
          {required: true, trigger: 'blur', message: '活动时间不能为空'},
        ],*/
      },
    }
  },
  created() {
    this.getCategoryList()
    this.getCouponList()
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    //添加优惠券
    addCoupons() {
      this.couponObj.forEach(item => {
        this.couponArr.forEach(child => {
          if (item == child.id) {
            this.list.push(child)
          }
        })
      })
      const map = new Map()
      // 对id属性进行去重
      this.list = this.list.filter(key => !map.has(key.id) && map.set(key.id, 1))
      if (this.list.length < 3) {
        this.couponType = false
      }
      this.couponObj = []
    },
    //监听优惠券数量
    monitoringCoupon() {
      if (this.list.length == 3) {
        this.couponType = true
        this.$message.error('最多只能选择三张优惠券')
      } else {
        this.couponType = false
      }
    },
    //监听优惠券选择
    getCouponNum(e) {
      if (e.length + this.list.length == 3) {
        // this.$message.success('')
        this.couponType = true
      } else {
        this.couponType = false
      }
    },
    //获取优惠券列表-不分页
    getCouponList() {
      apiGetNotPageCoupon().then(res => {
        if (res.code == 200) {
          this.couponArr = res.data
        }
      })
    },
    //获取商品分类
    getCategoryList() {
      apiGetCategoryList().then(res => {
        if (res.code == 200) {
          this.valueArr = res.data;
        }
      })
    },
    //获取时间段
    getTimeSection(event) {
      if (event != null) {
        this.form.begin_time = event[0]
        this.form.end_time = event[1]
      } else {
        this.form.begin_time = ''
        this.form.end_time = ''
      }
    },
    showPopSubmit(e) {
      this.showPop = true;
      console.log(e)
      if (e) {
        e.forEach(item => {
          this.s_list.push({
            sku_id: item.skuid,
            imgUrl: item.gallery,//商品图片
            name: item.name,//商品名
            original_price: item.price,//价格
            stock: item.stock,//库存
            price: item.price,//折扣价
          })
        })
        const smap = new Map()
        // 对id属性进行去重
        this.s_list = this.s_list.filter(key => !smap.has(key.sku_id) && smap.set(key.sku_id, 1))
        this.listLoading = false
      }
    },
    //删除选中商品
    handleDelete(row) {
      if (row.sku_id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.s_list.map((val, i) => {
            if (val.sku_id == row.sku_id) {
              this.s_list.splice(i, 1)
            }
          })
        })
      } else {
        this.$confirm('你确定要删除所有选中项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (var j = 0; j < this.selectRows.length; j++) {
            this.s_list.map((val, i) => {
              if (val.sku_id == this.selectRows[j].sku_id) {
                this.s_list.splice(i, 1)
              }
            })
          }
        })
      }
    },
    //清空表单
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.query_time = []
      this.s_list = []
    },
    async submit() {
      this.list = []
      if (this.s_list.length > 0) {
        this.s_list.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            price: item.price,//低价
          })
        })
      } else {
        this.$message.error('参与活动商品不能为空')
        return
      }
      const map = new Map()
      this.form.goods = this.list.filter(key => !map.has(key.sku_id) && map.set(key.sku_id, 1))
      apiGetDiscount(this.form).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.close()
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}

</script>
<style>
.warn {
  background-color: #fdf6ec;
  color: #e6a23c;
  padding: 8px 16px;
  font-size: 13px;
}
</style>
